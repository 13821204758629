import { CheckCircleTwoTone } from '@ant-design/icons';
import { Progress } from 'antd';
import hexToRgba from 'hex-to-rgba';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import useInterval from '../../utils/hooks/useInterval';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';

const PollResultCard = ({
  text,
  percentageOfReplies,
  numberOfReplies,
  isCorrectAnswer,
}) => {
  const [percentage, setPercentage] = useState(0);
  const { accentColor,pageTheme } = GetBrandSettingFromRecoil();
  const { t } = useTranslation();

  useInterval(() => {
    if (percentage === percentageOfReplies) return;
    setPercentage(percentage + 1);
  }, 10);

  return (
    <div className="poll__result__card">
      <h3>{text}</h3>
      {isCorrectAnswer && (
        <CheckCircleTwoTone
          style={{ display: 'inline-block', fontSize: 18, marginLeft: 8 }}
          twoToneColor={'#87d068'}
        />
      )}

      <div className="progress__container">
        <Progress
          showInfo={false}
          strokeColor={{
            '0%': hexToRgba(isCorrectAnswer ? '#87d068' : accentColor, 1),
            '100%': hexToRgba(isCorrectAnswer ? '#87d068' : accentColor, 0.7)
          }}
          percent={percentage}
        />
        <p>
          {percentageOfReplies}% ({numberOfReplies} {t('poll-votes')})
        </p>
      </div>

      <style jsx>{`
        .poll__result__card {
          border: 2px solid
            ${isCorrectAnswer
              ? '#87d068'
              : pageTheme === 'light'
              ? '#eee'
              : '#666'};
          border-radius: 4px;
          background: rgba(
            ${pageTheme === 'light' ? '255, 255, 255' : '0,0,0'},
            0.8
          );
          transition: all 0.2s ease-in-out;
          padding: 8px 16px 0 16px;
          margin: 0 0 8px;
        }
        .progress__container {
          display: flex;
          align-items: center;
        }
        .progress__container p {
          font-weight: 600;
          text-align: right;
          min-width: 120px;
          margin: 0 0 0 8px;
        }
        h3 {
          display: inline;
          font-size: 14px;
          line-height: 1.2;
          margin: 0;
        }
      `}</style>
      <style global jsx>{`
        .poll__result__card .ant-progress-line {
          display: flex;
          align-items: center;
        }
        .poll__result__card .ant-progress-text {
          font-size: 18px;
          text-align: right;
        }
        .poll__result__card .ant-progress-bg {
          background-color: transparent !important;
        }
        .poll__result__card .ant-progress-inner {
          background-color: #ccc;
        }
      `}</style>
    </div>
  );
};

export default PollResultCard;
