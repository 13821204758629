// import { LineChartOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'next-i18next';
import { FaPoll } from 'react-icons/fa';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';

const PollButton = ({ isMuted, setIsOpen }) => {
  const screens = useBreakpoint();
  const { t } = useTranslation("common");
  const { accentColor } = GetBrandSettingFromRecoil();
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div className="poll__button" >
      <Tooltip title={t('poll-open')} placement="right">
        {/* <div className={`poll__action blink`}> */}
        <Button
          type="link"
          onClick={handleOpen}
          data-cy="poll-button"
          icon={
            <div className="blink-icon">
              <FaPoll style={{ fontSize: screens.sm ? 40 : 16 }} />
              {/* <LineChartOutlined style={{ fontSize: screens.sm ? 24 : 16 }} /> */}
            </div>
          }
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: screens.sm ? 70 : 32,
            width: screens.sm ? 70 : 32,
            padding: 16,
            transition: 'none'
          }}
        ></Button>
        {/* </div> */}
      </Tooltip>
      <style jsx>{`
        .poll__button {
          position: absolute;
          top: ${!isMuted ? '40px' : '120px'};
          left: ${screens?.md ? '25px' : '8px'};
          z-index: 500;
        }
        .poll__action {
          border-radius: 50%;
        }
        .blink {
          background: ${hexRgb(accentColor, { format: 'css', alpha: 0.9 })};
          animation: blinker 1.5s infinite;
        }
        .blink-icon {
          color: ${hexRgb('#000', { format: 'css', alpha: 0.6 })};
          animation: blinkerReverse 1.5s infinite;
        }

        @keyframes blinker {
          from {
            background: ${hexRgb(accentColor, {
              format: 'css',
              alpha: 0.9
            })};
          }
          50% {
            background: ${hexRgb('#fff', { format: 'css', alpha: 0.6 })};
          }
          to {
            background: ${hexRgb(accentColor, {
              format: 'css',
              alpha: 0.9
            })};
          }
        }

        @keyframes blinkerReverse {
          from {
            color: ${hexRgb('#fff', { format: 'css', alpha: 0.6 })};
          }
          50% {
            color: ${hexRgb(accentColor, { format: 'css', alpha: 0.9 })};
          }
          to {
            color: ${hexRgb('#fff', { format: 'css', alpha: 0.6 })};
          }
        }
      `}</style>
    </div>
  );
};

export default PollButton;
