import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_POLL = gql`
  query poll($pollId: ID!) {
    poll(id: $pollId) {
      id
      pollQuestions {
        id
        questionIsPublished
        answerIsPublished
        questionStartedAt
        questionEndedAt
        answerStartedAt
        answerEndedAt
        text
        type
        allowMultiAnswers
        numberOfReplies
        percentageOfCorrectReplies
        pollAnswers {
          id
          text
          numberOfReplies
          isCorrectAnswer
          percentageOfReplies
          order
        }
        words {
          text
          value
        }
      }
      media {
        pageSetting {
          accentColor
          pageTheme
        }
      }
      live {
        pageSetting {
          accentColor
          pageTheme
        }
      }
    }
  }
`;

export const GET_CURRENT_POLL_QUESTION = gql`
  query poll($pollId: ID!) {
    poll(id: $pollId) {
      id
      pollCurrentQuestion {
        id
        questionIsPublished
        answerIsPublished
        questionStartedAt
        questionEndedAt
        text
        type
        allowMultiAnswers
        numberOfReplies
        percentageOfCorrectReplies
        pollAnswers {
          id
          text
          numberOfReplies
          isCorrectAnswer
          percentageOfReplies
          order
        }
        words {
          text
          value
        }
      }
      media {
        pageSetting {
          accentColor
          pageTheme
        }
      }
      live {
        pageSetting {
          accentColor
          pageTheme
        }
      }
    }
  }
`;
