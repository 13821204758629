import { useMutation, useQuery } from '@apollo/client';
import { CREATE_POLL_REPLIES, CREATE_POLL_REPLY } from '../mutations/poll';
import { GET_CURRENT_POLL_QUESTION, GET_POLL } from '../queries/poll';

export const GetPollCurrentQuestion = (pollId, skip) => {
  const { data, loading, error, subscribeToMore } = useQuery(
    GET_CURRENT_POLL_QUESTION,
    {
      variables: {
        pollId
      },
      skip
    }
  );

  if (error) {
    throw new Error(error);
  }

  return {
    poll: data?.poll,
    subscribeToMore,
    loading,
    error
  };
};

export const GetPoll = (pollId, skip) => {
  const { data, loading, error } = useQuery(GET_POLL, {
    variables: {
      pollId
    },
    skip
  });

  if (error) {
    throw new Error(error);
  }

  return {
    poll: data?.poll,
    loading,
    error
  };
};

// MUTATION
export const useCreatePollReply = () => {
  const [createPoleReply] = useMutation(CREATE_POLL_REPLY);
  return async (variables) => {
    try {
      const { data } = await createPoleReply({
        variables
      });
      return {
        data
      };
    } catch (error) {
      console.log('error2', error);
      return {
        error
      };
    }
  };
};

export const useCreatePollReplies = () => {
  const [createPollReplies, { loading }] = useMutation(CREATE_POLL_REPLIES);
  return {
    createPollRepliesLoading: loading,
    createPollReplies: (variables) => createPollReplies({ variables })
  };
};
