export const getVODActiveQuestion = (pollQuestions, type, position) => {
  return pollQuestions.find(
    (question) =>
      Number(
        question[type === 'question' ? 'questionStartedAt' : 'answerStartedAt']
      ) > 0 &&
      Number(
        question[type === 'question' ? 'questionStartedAt' : 'answerStartedAt']
      ) < position &&
      Number(
        question[type === 'question' ? 'questionEndedAt' : 'answerEndedAt']
      ) > 0 &&
      Number(
        question[type === 'question' ? 'questionEndedAt' : 'answerEndedAt']
      ) > position
  );
};
