import { useEffect, useReducer } from 'react';

const MountAnimation = ({ children }) => {
  const [isMounted, toggleMounted] = useReducer((p) => !p, true);
  const [isAnimateAnmount, toggleAnimateUnmount] = useReducer((p) => !p, false);
  const [isVisible, toggleVisible] = useReducer((p) => (p ? 0 : 1), 0);

  useEffect(() => {
    if (isAnimateAnmount) {
      toggleVisible();
      toggleAnimateUnmount();
      setTimeout(() => {
        toggleMounted();
      }, 600);
    }
  }, [isAnimateAnmount]);

  useEffect(() => {
    toggleVisible();
  }, [isMounted]);

  return (
    <div
      style={{
        opacity: isVisible,
        transform: `translateY(${isVisible ? '0' : '12px'})`,
        transition: 'all .6s ease',
        width: '100%'
      }}
    >
      {children}
    </div>
  );
};

export default MountAnimation;
