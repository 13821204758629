import ReactWordcloud from 'react-wordcloud';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
// import tinycolor2 from 'tinycolor2';
import PollResultCard from './PollResultCard';

const PollResult = ({
  text,
  pollAnswers,
  words,
  type
}) => {
  const { accentColor } = GetBrandSettingFromRecoil();
  const reactWordsCloudOptions = {
    colors: [
      accentColor
      // tinycolor2(accentColor).lighten(),
      // accentColor,
      // tinycolor2(accentColor).darken()
    ],
    enableTooltip: false,
    deterministic: true,
    enableOptimizations: true,
    fontFamily: 'Poppins',
    fontSizes: [20, 80],
    fontWeight: '600',
    padding: 1,
    rotations: 3,
    rotationAngles: [0],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 500
  };
  
  const reactWordsCloudCallbacks = {
    getWordTooltip: (word) => `${word.text} (${word.value} votes)`
  };

  return (
    <div className="poll__results" data-cy="poll-results">
      <h2 data-cy="poll-results-title">{text}</h2>
      {type === 'oneWord' ? (
        <div className="poll__results__one-word">
          <ReactWordcloud
            callbacks={reactWordsCloudCallbacks}
            options={reactWordsCloudOptions}
            words={words}
          />
        </div>
      ) : (
        pollAnswers &&
        pollAnswers.length > 0 &&
        pollAnswers.map((pollAnswer) => (
          <PollResultCard
            key={pollAnswer.id}
            text={pollAnswer.text}
            percentageOfReplies={pollAnswer.percentageOfReplies}
            isCorrectAnswer={pollAnswer.isCorrectAnswer}
            numberOfReplies={pollAnswer.numberOfReplies}
          />
        ))
      )}

      <style jsx>{`
        .poll__results {
          width: 100%;
        }
        .poll__results__one-word {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default PollResult;
