import { gql } from '@apollo/client'; // eslint-disable-line import/no-extraneous-dependencies

export const GET_UPDATED_POLL = gql`
  subscription getUpdatedPoll($pollId: ID!) {
    poll(pollId: $pollId) {
      id
      pollCurrentQuestion {
        id
        questionIsPublished
        answerIsPublished
        questionStartedAt
        questionEndedAt
        answerStartedAt
        answerEndedAt
        text
        type
        allowMultiAnswers
        numberOfReplies
        percentageOfCorrectReplies
        pollAnswers {
          id
          text
          numberOfReplies
          isCorrectAnswer
          percentageOfReplies
          order
        }
        words {
          text
          value
        }
      }
    }
  }
`;
